<template>
  <div class="my-container">
    <img class="address-icon" src="@/assets/images/address_icon.png" alt="">
    <div class="address-info">
      <div class="address-item">四川群峰环保科技有限公司</div>
      <div class="address-item">电话：028-8693-0810</div>
      <div class="address-item">地址：四川省金牛区华侨城创想中心A座1501室</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MyInfo',
  data () {
    return {

    }
  },
  methods: {

  }

}

</script>

<style lang="scss" scoped>

.my-container {
  flex: 1;
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  .address-icon {
    margin-bottom: 35px;
    flex: 0 0 auto;
    width: 100%;
    height: 273px;
  }
  .address-info {
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    .address-item {
      margin-bottom: 17px;
      height: 21px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      line-height: 30px;
      color: #4E4E4E;
    }
  }
}

</style>
